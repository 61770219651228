import { SimulationService } from 'src/app/core/simulation-service/simulation.service';
import { User } from 'src/app/core/interfaces/user';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/user-service/user.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FutProfile } from '../interfaces/profile';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { createCustomHeader } from '../util/http.util';
import { getTime } from '../util/date.util';
import { PermissionsService } from '../permissions-service/permissions.service';
import { getOnlyCat1 } from 'src/app/shared/category/util/category.util';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  readonly LOCALSTORAGE_KEY_USER_PREPARATION = 'user-preparation-level';
  readonly TIME_TO_UPDATE = 600000 * 2; // 20 Minutes
  private readonly LOCAL_STORAGE_KEY = 'fut-profile-categories';

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private simulationService: SimulationService,
    private permissionService: PermissionsService
  ) {}

  public getCategories(forceUpdate = false): Observable<Array<string>> {
    if (!forceUpdate) {
      const catStr = localStorage.getItem(this.LOCAL_STORAGE_KEY);
      const expire = localStorage.getItem(this.LOCAL_STORAGE_KEY + '-expire');

      if (catStr && expire && moment().diff(moment(expire), 'hours') <= 12) {
        return of(JSON.parse(catStr));
      }
    }

    return this.http.get<Array<string>>(environment.be_url + '/profiles/user').pipe(
      switchMap(profiles => this.simulationService.getAllCategories().pipe(map(categories => categories.filter(category => profiles.includes(category))))),
      tap(profiles => localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(profiles))),
      tap(() => localStorage.setItem(this.LOCAL_STORAGE_KEY + '-expire', new Date().toISOString()))
    );
  }

  public getCategory1WithPermissions(forceUpdate = false): Observable<Array<{ name: string; locked: boolean }>> {
    return this.getCategories(forceUpdate).pipe(
      map(category => getOnlyCat1(category)),
      switchMap(categories => forkJoin(categories.map(cat => this.getCatPermission(cat))))
    );
  }

  private getCatPermission(category: string): Observable<{ name: string; locked: boolean }> {
    return this.permissionService.check('lock_category_' + category).pipe(map(locked => ({ name: category, locked: !locked })));
  }

  public list(): Observable<Array<FutProfile>> {
    return forkJoin([this.userService.getFuturaUser(), this.http.get<Array<FutProfile>>(environment.be_url + '/profiles/')]).pipe(
      map(([user, profiles]) => profiles.filter(p => !p.content.created_by_user || p.content.created_by === user.instance_id))
    );
  }

  public select(profile_id: string): Observable<User> {
    return this.http
      .get<User>(
        environment.be_url + '/profiles/select',
        createCustomHeader({
          target_resource: profile_id,
        })
      )
      .pipe(
        switchMap(() => {
          localStorage.removeItem(this.LOCAL_STORAGE_KEY);
          this.userService.clearFuturaUserCache();
          return this.userService.getFuturaUser(true);
        })
      );
  }

  getUserPreparation(group_by: undefined): Observable<number>;
  getUserPreparation(group_by: 'cat1' | 'cat2' | 'cat3' | 'all'): Observable<{ [key: string]: number }>;
  getUserPreparation(group_by?: 'cat1' | 'cat2' | 'cat3' | 'all' | undefined):
    | Observable<number>
    | Observable<{
        [key: string]: number;
      }> {
    if (group_by) {
      const localstorage_info = JSON.parse((localStorage.getItem(`${group_by}-${this.LOCALSTORAGE_KEY_USER_PREPARATION}`) || '{}') as any) as {
        time: number;
        preparation_level: { [key: string]: number };
      };
      if (localstorage_info && localstorage_info.time + this.TIME_TO_UPDATE > getTime()) {
        return of(localstorage_info.preparation_level);
      }
      return this.http
        .get<{
          [key: string]: number;
        }>(
          environment.be_url + '/users/adaptive/preparation_level',
          createCustomHeader({
            custom_params: { 'Group-By': group_by },
          })
        )
        .pipe(
          tap(pl =>
            localStorage.setItem(
              `${group_by}-${this.LOCALSTORAGE_KEY_USER_PREPARATION}`,
              JSON.stringify({
                preparation_level: pl,
                time: getTime(),
              })
            )
          )
        );
    } else
      return this.http.get<number>(environment.be_url + '/users/adaptive/preparation_level').pipe(
        tap(pl =>
          localStorage.setItem(
            this.LOCALSTORAGE_KEY_USER_PREPARATION,
            JSON.stringify({
              preparation_level: pl,
              time: getTime(),
            })
          )
        )
      );
  }
}
