export interface Category {
  blank_count: number;
  category_path: string;
  material_seen_count: number;
  material_seen_time: number;
  material_total_count: number;
  material_total_time: number;
  name: string;
  preparation_level: number;
  total_questions_count: number;
  total_count: number;
  wrong_count: number;
  correct_count: number;
  correct_count_avg: number;
  preparation_level_avg: number;

  // FE utility attributes
  prepPercentage: number;
  assessment: number;
  answerToUnlockAssessment: Array<number>;
}

export interface Archive extends Category {
  children: Array<Archive | Category>;
}

export interface UnlockInfo {
  unlock: boolean;
  needed: number;
  missing: number;
}

export const ANSWER_TO_UNLOCK_PREPARATION = [100, 20, 5]; // cat1, cat2, cat3
export const ANSWER_TO_UNLOCK_ASSESSMENT_MIN = [60, 40, 20]; // cat1, cat2, cat3
export const ANSWER_TO_UNLOCK_ASSESSMENT_MAX = [300, 100, 20]; // cat1, cat2, cat3
