import { ANSWER_TO_UNLOCK_ASSESSMENT_MAX, ANSWER_TO_UNLOCK_ASSESSMENT_MIN, Archive, Category, UnlockInfo } from '../model/category.model';

export function getUnlockInfo(answerToUnlock: Array<number>, row: Category, level: number): UnlockInfo {
  const unlockAmount = answerToUnlock[level];
  if (unlockAmount == null) {
    throw new Error(`Impossible to find unlockAmount for level: ${level}!`);
  }

  const { total_count, total_questions_count } = row;

  const needed = Math.min(total_questions_count, unlockAmount);

  return {
    unlock: total_count >= needed,
    needed,
    missing: Math.max(0, needed - total_count),
  };
}

export function calcPrepPercentage(row: Category): number {
  if (!row) {
    return 0;
  }

  const { material_total_count, material_seen_count, preparation_level_avg } = row;
  let materialPrep = 0;
  let maxTestPrep = 100;
  if (material_total_count) {
    maxTestPrep = 90;
    materialPrep = (material_seen_count / material_total_count) * 10;
  }
  const testPrep = (preparation_level_avg / 100) * maxTestPrep;
  return +Math.min(testPrep + materialPrep, 100).toFixed(2);
}

export function removeCatWithoutQuestion<T extends Archive | Category>(archive: Array<T>): Array<T> {
  for (const a of archive) {
    if (!isArchive(a) || !a.children.length) {
      continue;
    }
    a.children = removeCatWithoutQuestion(a.children);
  }
  return archive.filter(a => a.total_questions_count > 0);
}

export function isArchive(el: Archive | Category): el is Archive {
  return 'children' in el;
}

export function calculateExtraFields(archive: Array<Archive | Category>): void {
  internalCalculateExtraFields(archive);
}

function internalCalculateExtraFields(archive: Array<Archive | Category>, level = 0): void {
  archive.forEach(row => {
    row.prepPercentage = calcPrepPercentage(row);
    row.assessment = calcPrepPercentage(row) / 100;

    if (isArchive(row) && row.children.length) {
      internalCalculateExtraFields(row.children, level + 1);
      const tmpUnlock = row.children.map(c => c.answerToUnlockAssessment[level + 1]).reduce((a, b) => a + b, 0);
      const currentUnlock = Math.min(ANSWER_TO_UNLOCK_ASSESSMENT_MAX[level], Math.max(ANSWER_TO_UNLOCK_ASSESSMENT_MIN[level], tmpUnlock * 0.5));

      row.children.forEach(c => (c.answerToUnlockAssessment[level] = currentUnlock));
      row.answerToUnlockAssessment = [...row.children[0].answerToUnlockAssessment];
    } else {
      row.answerToUnlockAssessment = new Array(level + 1).fill(0);
      row.answerToUnlockAssessment[level] = ANSWER_TO_UNLOCK_ASSESSMENT_MIN[level];
    }
  });
}

export function getOnlyCat1(categories: Array<string>): Array<string>;
export function getOnlyCat1(categories: string): string;
export function getOnlyCat1(categories: Array<string> | string): Array<string> | string {
  if (Array.isArray(categories)) {
    return Array.from(new Set(categories.map(c => c.split('.')[0])));
  }

  return categories.split('.')[0];
}
